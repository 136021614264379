import AddButton from "@components/common/AddButton";
import { onEnterDown } from "@components/utils/accessibilityHelpers";
import { sanitizeBudgetAndTravelers } from "@components/utils/sanitizeHelpers";
import {
    IAccommodations,
    IQueryItemAccommodation,
    IQueryItemActivityType,
    IQueryItemExperience,
    IQueryItemLocationType,
    ISearchManagementTab,
} from "@customTypes/filtersProvider";
import { generateNumberOfNightsText } from "@hooks/useFormatDate";
import { FiltersContext } from "providers/FiltersProvider";
import { useCallback, useContext, useMemo } from "react";
import Chip from "./Chip";

interface IFilterModalAccordionItem {
  tab: ISearchManagementTab;
  showAccordion: boolean;
  tabSearchQuery?:
    | IQueryItemExperience[]
    | IQueryItemLocationType[]
    | IAccommodations
    | IQueryItemActivityType[];
  isLastItem: boolean;
}

const FilterModalAccordionItem = ({
  tab,
  showAccordion,
  tabSearchQuery,
  isLastItem,
}: IFilterModalAccordionItem): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const onRemoveHandler = useMemo(() => {
    switch (tab.index) {
      case 0:
        return () => {
          filtersContext?.removeExperience();
        };

      case 2:
        return (item: IQueryItemLocationType) => {
          filtersContext?.removeLocation(item);
        };

      case 3:
        return (item: IQueryItemAccommodation) => {
          filtersContext?.removeAccommodation(item);
        };
      case 4:
        return (item: IQueryItemActivityType) => {
          filtersContext?.removeActivity(item);
        };
      default:
        return () => {};
    }
  }, [tab.index, filtersContext]);

  const callapsedAccordionContent = useCallback(() => {
    switch (tab.index) {
      case 1:
        if (filtersContext?.calendarUtils.inputDate) {
          return (
            <>
              <h6 className="pt-1 text-h6 text-darkblue">
                {filtersContext?.calendarUtils.inputDate}
              </h6>
              <Chip
                key={"calendar_dates"}
                item={{
                  title: generateNumberOfNightsText(
                    filtersContext?.calendarUtils?.numberOfNights
                  ),
                }}
              />
            </>
          );
        }
        break;

      case 3:
        const accommodationSearchQuery = tabSearchQuery as IAccommodations;

        const minBudget = filtersContext?.searchQuery.accommodations.budget.min;
        const maxBudget = filtersContext?.searchQuery.accommodations.budget.max;
        const roomsAmount = filtersContext?.searchQuery.travelers.rooms;

        const accomodationsList = [
          ...accommodationSearchQuery?.hotels.categories,
          ...accommodationSearchQuery?.rentals?.categories,
        ];

        const { isDefaultBudget, isDefaultRoom, roomPreferencesList } =
          sanitizeBudgetAndTravelers(minBudget, maxBudget, roomsAmount);

        const categoriesList = [
          accommodationSearchQuery?.hotels,
          accommodationSearchQuery?.rentals,
        ].filter((item) => item.isChecked);

        const contentList: JSX.Element[] = [];

        contentList.push(
          ...categoriesList.map((item) => {
            return (
              <Chip
                key={item.title}
                item={{
                  title: item.title,
                }}
                onClick={() => {
                  filtersContext?.changeAccommodationCategory(item);
                }}
              />
            );
          }),
          ...accomodationsList.map((item) => {
            return (
              <Chip
                key={item.title}
                item={item}
                onClick={() => {
                  const onRemoveHandlerFunction = onRemoveHandler as (
                    accommodation: IQueryItemAccommodation
                  ) => void;
                  onRemoveHandlerFunction(item);
                }}
              />
            );
          })
        );

        if (
          categoriesList.length > 0 ||
          accomodationsList.length > 0 ||
          !(isDefaultBudget && isDefaultRoom)
        ) {
          contentList.push(
            ...roomPreferencesList.map((item) => {
              return <Chip key={item} item={{ title: item }} />;
            })
          );
        }

        return contentList;

      default:
        const defaultSearchQuery = tabSearchQuery as
          | IQueryItemExperience[]
          | IQueryItemLocationType[]
          | IQueryItemActivityType[];

        if (defaultSearchQuery.length > 0) {
          return defaultSearchQuery.map((item) => (
            <Chip
              key={item.id}
              item={item}
              onClick={() => {
                // @ts-expect-error
                onRemoveHandler(item);
              }}
            />
          ));
        }
        break;
    }
  }, [filtersContext]);

  const addButtonContent = useMemo(() => {
    switch (tab.index) {
      case 0:
        return tabSearchQuery?.length > 0 ? "" : <AddButton />;

      case 1:
        return filtersContext?.calendarUtils.inputDate ? (
          ""
        ) : (
          <AddButton variant={"calendar"} />
        );

      default:
        return <AddButton />;
    }
  }, [filtersContext, tab.index, tabSearchQuery?.length]);

  const onClickHandler = (): void => {
    showAccordion
      ? filtersContext?.closeAccordion(tab.index)
      : tab.onExpandHandler();
  };

  return (
    <div
      className={`w-full bg-blue-5 ${
        tab.index === 0 ? "desktop:rounded-t-xl" : ""
      } ${
        isLastItem ? "desktop:rounded-b-xl" : "border-b-[1px] border-blue-20"
      } ${showAccordion ? "" : "cursor-pointer desktop:hover:bg-blue-10"}`}
      id={tab.tabName}
    >
      <div
        className={`m-4 h-fit desktop:gap-6 ${
          showAccordion ? "cursor-pointer" : "flex flex-row items-center gap-2"
        }`}
        onClick={onClickHandler}
        tabIndex={0}
        onKeyDown={(e) => onEnterDown(e, onClickHandler)}
      >
        <h6
          className={`pt-1 text-h6 ${
            showAccordion ? "" : "w-[100px]"
          } font-medium`}
        >
          {showAccordion ? tab.longTitle : tab.shortTitle}
        </h6>
        {!showAccordion && (
          <div className="flex h-fit w-full flex-wrap items-center gap-2">
            {callapsedAccordionContent()}
            {addButtonContent}
          </div>
        )}
      </div>

      {showAccordion && <div className="h-fit items-center">{tab.content}</div>}
    </div>
  );
};

export default FilterModalAccordionItem;
