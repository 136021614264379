import { useMemo } from 'react'
import useScreenType from './useScreenType'

interface IUseDetectDeviceReturn {
  isMobile: boolean
  isRealMobileDevice: boolean
  isDesktop: boolean
  isTablet: boolean
  screenTypeVariant: string
}

const useDetectDevice = (): IUseDetectDeviceReturn => {
  const screenType = useScreenType()

  const result = useMemo(
    () => ({
      isDesktop: screenType.type === 'desktop',
      isTablet: screenType.type === 'tablet',
      isMobile: screenType.type === 'mobile',
      isRealMobileDevice: screenType.variant === 'real',
      screenTypeVariant: screenType.variant,
    }),
    [screenType],
  )
  return result
}

export default useDetectDevice
