import MediaAssets from "@components/utils/MediaAssets";
import { PAGE_TYPES } from "@customTypes/index";
import useAnalytics from "@hooks/useAnalytics";
import useAppContext from "@hooks/useAppContext";
import useModalScrollFreeze from "@hooks/useModalScrollFreeze";
import GoogleTagManager from "@modules/analytics/GoogleTagManager";
import { getCookie, setCookie } from "cookies-next";
import { NextPage } from "next";
import ServerErrorPage from "pages/500";
import RedirectingPage from "pages/redirecting";
import { useCallback, useEffect } from "react";
import ThankYouPayment from "../../pages/thank-you-payment";
import { IAppPagePropsResponse } from "../../types/commonResponses";
import MetaTags from '@components/head/MetaTags'
import Header from '@components/header/Header'
import Toast from '@components/common/Toast'
import HeaderShadowMobile from '@components/header/HeaderShadowMobile'
import Footer from '@components/footer/Footer'
import FilterModal from '@components/SearchManagement/FilterModal'

export interface IAppContent {
  Component: NextPage<IAppPagePropsResponse>;
  pageProps: IAppPagePropsResponse;
}

const AppContent = ({ Component, pageProps }: IAppContent): JSX.Element => {
  const appContext = useAppContext();

  useModalScrollFreeze([
    appContext?.showHeaderMenuMobile,
  ]);

  const handleClose = useCallback(() => {
    appContext?.setShowToastMessage(false);
  }, []);

  useEffect(() => {
    /* Validate that the cookie does not exist */
    if (!getCookie("roasurl")) {
      /* Create a variable with the url */
      const urlVariables = location.search.substring(1);
      const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
      /* Valid that the url contains "utm" */
      if (urlVariables.includes("utm")) {
        setCookie("roasurl", window.location.href, {
          maxAge: thirtyDaysInSeconds,
        }); /* save cookie for 30 days */
        /* If the url does not contain utm save the referrer */
      } else if (document.referrer) {
        setCookie("roasurl", document.referrer, {
          maxAge: thirtyDaysInSeconds,
        });
        /* If the url does not contain utm and the referrer is false save the first url */
      } else {
        setCookie("roasurl", window.location.href, {
          maxAge: thirtyDaysInSeconds,
        });
      }
    }

    // This is used to mark when a page is completely rendered so prerender.io can take a snapshot of it
    // 3 seconds is the time chosen to ensure that images have finished downloading
    setTimeout(() => {
      // @ts-expect-error
      window.prerenderReady = true;
    }, 3000);
  }, []);

  useAnalytics(pageProps);

  MediaAssets.loadAssets(pageProps?.mediaAssets);

  return (
    <>
      <MetaTags {...pageProps} />
      {pageProps.pageType === PAGE_TYPES.ThankYouPaymentPage ? (
        <ThankYouPayment/>
      ) : (
        <div id="root-body" className="w-full">
          {pageProps.pageType === PAGE_TYPES.RedirectingPage ? (
            <RedirectingPage/>
          ) : (
            <>
              <Header {...pageProps} />
              {appContext?.showToastMessage && (
                <Toast
                  position="fixed"
                  text={appContext?.toastMessage}
                  onClose={handleClose}
                />
              )}
              {pageProps.hasInternalError ? (
                // Handles statusCode 500
                <ServerErrorPage/>
              ) : (
                <Component {...pageProps} />
              )}
              <HeaderShadowMobile/>
              <Footer
                footer={pageProps?.footer}
                topNavigation={pageProps?.topNavigation}
                usp={pageProps?.usp}
                pageType={pageProps?.pageType}
              />
              <FilterModal/>
            </>
          )}
        </div>
      )}
      <GoogleTagManager/>
    </>
  );
};

export default AppContent;
