import { ImageDefault } from "@components/utils/Utils";
import { STATIC_LANDING_PAGES_URLS } from "@components/utils/constants";
import { IImageComponentResponse } from "@customTypes/componentResponses";
import Image from "next/legacy/image";
import Link from "next/link";

interface IHeaderTripadvisorLogoProps {
  isDesktop?: boolean;
  tripadvisorLogo: IImageComponentResponse;
}

const HeaderTripadvisorLogo = ({
                                 isDesktop,
                                 tripadvisorLogo,
                               }: IHeaderTripadvisorLogoProps): JSX.Element => {
  return (
    <div
      className={`${
        isDesktop ? "hidden desktop:block" : ""
      } my-auto mx-auto h-[24px] w-fit desktop:border-r-[2px] desktop:border-blue-10 desktop:pr-3 desktopLarge:pr-4`}
    >
      <Link
        href={STATIC_LANDING_PAGES_URLS.REVIEWS}
        prefetch={false}
      >
        <Image
          src={ImageDefault(tripadvisorLogo)}
          width="85"
          height="24"
          layout="fixed"
          alt="tripadvisor_rating"
        />
      </Link>
    </div>
  );
};

export default HeaderTripadvisorLogo;
