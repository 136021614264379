import {
  IReview,
  ITravelConsultant,
} from '@components/pages/travel-consultant/components/TCDetailsLayout'
import { BUDGETS, TRAVELERS } from '@components/utils/constants'
import {
  ITagResponse,
  ITravelConsultantResponse,
} from '@customTypes/modelResponses'
import { useRef } from 'react'

export const sanitizeBudgetNumber = (budget: number): string => {
  return `$${
    budget <= 999
      ? budget
      : `${budget.toString().substring(0, 1)},${budget
          .toString()
          .substring(1)}${budget === BUDGETS.MAX ? '+' : ''}`
  }`
}

interface ISanitizeBudgetAndTravelersResult {
  isDefaultBudget: boolean
  isDefaultRoom: boolean
  roomPreferencesList: string[]
}

export const sanitizeBudgetAndTravelers = (
  minBudget: number = 0,
  maxBudget: number = 1000,
  roomsAmount: number = 1,
): ISanitizeBudgetAndTravelersResult => {
  const isDefaultBudget = minBudget === BUDGETS.MIN && maxBudget === BUDGETS.MAX
  const isDefaultRoom = roomsAmount === TRAVELERS.ROOMS.DEFAULT

  const roomPreferencesList = [
    isDefaultBudget
      ? 'All Price Ranges'
      : `${`${sanitizeBudgetNumber(minBudget)} - ${sanitizeBudgetNumber(
          maxBudget,
        )}`}`,
    roomsAmount === TRAVELERS.ROOMS.MAX
      ? `${roomsAmount}+ rooms`
      : `${roomsAmount} ${roomsAmount > 1 ? 'rooms' : 'room'}`,
  ]

  return { isDefaultBudget, isDefaultRoom, roomPreferencesList }
}

export const sanitizeTravelConsultant = (
  travelConsultant: ITravelConsultantResponse,
): ITravelConsultant => ({
  name: travelConsultant.Name,
  image: travelConsultant.Media?.PrimaryImage?.[0],
  jobTitle: travelConsultant.JobTitle,
  tidbits: travelConsultant.TC_Tidbits?.map((tidbit) => {
    return {
      id: tidbit?.id,
      text: tidbit?.Tidbits,
    }
  }),
  specialties: travelConsultant.tags,
  bio: travelConsultant.Bio,
  reviews: travelConsultant.reviews?.map((review) => {
    const reviewTextParentRef = useRef(null)
    const sanitizedReview: IReview = {
      id: review.id,
      name: review.ReviewerName,
      headshot: review.Headshot ? review.Headshot[0]?.url : undefined,
      text: review.ReviewText,
      country: review.Country,
      date: review.Date,
    }
    return sanitizedReview
  }),
})

export interface ISanitizedTag {
  id: number
  title: string
}

interface ITagWithRequiredFields extends ITagResponse {
  id: number
  name: string
}

export const sanitizeTags = (
  tags: ITagResponse[] | undefined,
): ISanitizedTag[] | undefined => {
  const filteredTags = tags?.filter((tag): tag is ITagWithRequiredFields => {
    return !!tag.id && !!tag.name
  })
  const mappedTags: ISanitizedTag[] | undefined = filteredTags?.map((tag) => {
    return { id: tag.id, title: tag.name }
  })
  return mappedTags
}
