import { buildUrl } from "cloudinary-build-url";
import React, { useState } from "react";
import Image from "next/legacy/image";
import getConfig from "next/config";
import { ImageDefault } from "@components/utils/Utils";

declare type SafeNumber = number | `${number}`;

interface IImageCDNProps {
  // size,
  src: string;
  width?: SafeNumber;
  height?: SafeNumber;
  alt?: string;
  className?: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive";
  objectFit?: any;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  priority?: boolean;
  quality?: number;
  isHero?: boolean;
  fetchPriority?: "auto" | "high" | "low"
}

interface ISizeProps extends Pick<IImageCDNProps, 'width' | 'height' | 'layout'> {
  sizes?: string;
  style?: { width: string, height: string }
}

const { publicRuntimeConfig } = getConfig();

export const shimmer = (
  w: number | string | undefined,
  h: number | string | undefined
): string => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#E8E8E8" offset="20%" />
      <stop stop-color="#E9E9E9" offset="50%" />
      <stop stop-color="#E8E8E8" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#E8E8E8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str: string): string =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

const getCloudinaryUrl = ({ src, width, height, quality, cropType = 'fill' }: {
  src: string,
  width?: SafeNumber,
  height?: SafeNumber,
  quality?: number
  cropType?: string
}) => {
  const arr = src.split("/");
  const imageName = arr[7];
  return buildUrl(
    `${publicRuntimeConfig.FOLDER_NAME}/${imageName}`,
    {
      cloud: {
        cloudName: publicRuntimeConfig.CLOUD_NAME,
      },
      transformations: {
        quality,
        height,
        width,
        crop: cropType,
        format: "webp",
      },
    }
  );
}

function ImageCDN({
                    src,
                    width,
                    height,
                    alt,
                    className,
                    layout,
                    objectFit,
                    onClick,
                    quality = 75,
                    isHero = false,
                    fetchPriority
                  }: IImageCDNProps): JSX.Element {
  const [hasError, setHasError] = useState(false);

  let cloudinaryUrl = getCloudinaryUrl({ src, width, quality, cropType: 'scale' })

  if (isHero) {
    const srcset = [256, 384, 420, 640, 1200]
      .map(w => {
        const url = getCloudinaryUrl({ src, width: w, quality, cropType: 'scale' })
        return `${url} ${w}w`
      })
      .join(', ');
    
    // eslint-disable-next-line @next/next/no-img-element
    return <img
      src={cloudinaryUrl}
      sizes="(max-width: 576px) 50vw, 100vw"
      srcSet={srcset}
      alt={alt}
      decoding="async"
      data-nimg="fill"
      // @ts-ignore
      fetchpriority={fetchPriority}
      style={{
        position: 'absolute',
        inset: '0px',
        boxSizing: 'border-box',
        padding: '0px',
        border: 'none',
        margin: 'auto',
        display: 'block',
        width: '0px',
        height: '0px',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        maxHeight: '100%',
        objectFit: 'cover'
      }}
    />
  }

  const sizeProps: ISizeProps = {};

  if (width && height) {
    sizeProps.width = layout === 'fill' ? undefined : width;
    sizeProps.height = layout === 'fill' ? undefined : height;
    sizeProps.layout = layout;
    // sizeProps.sizes = layout && ['fill', 'responsive'].includes(layout) ? "(max-width: 768px) 480px, 960px" : undefined;
  } else {
    sizeProps.layout = "fill";
    // Make the image display full width
    sizeProps.style = {
      width: '100%',
      height: 'auto',
    };
  }

  return (
    <Image
      unoptimized
      className={className}
      src={hasError ? ImageDefault(null) : cloudinaryUrl}
      alt={alt}
      objectFit={objectFit}
      onClick={onClick}
      priority={false}
      placeholder="blur"
      blurDataURL={`data:image/svg+xml;base64,${toBase64(
        shimmer(width, height)
      )}`}
      onError={() => setHasError(true)}
      {...sizeProps}
      // @ts-ignore
      fetchpriority={fetchPriority}
    />
  );
}

export default ImageCDN;
