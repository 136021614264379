import "../styles/main.css";
import ErrorBoundary from "../middleware/errorBoundary";
import { FiltersProvider } from "providers/FiltersProvider";
import { AppUtilsProvider } from "providers/AppUtilsProvider";

import AppContent, { IAppContent } from "@modules/app/AppContent";
import { HeaderProvider } from '../context/HeaderContext'

export default function MyApp({
                                Component,
                                pageProps,
                              }: IAppContent): JSX.Element {
  return (
    <ErrorBoundary>
      <FiltersProvider pageProps={pageProps}>
        <AppUtilsProvider>
          <HeaderProvider>
            <AppContent Component={Component} pageProps={pageProps}/>
          </HeaderProvider>
        </AppUtilsProvider>
      </FiltersProvider>
    </ErrorBoundary>
  );
}
