import HeaderTripadvisorLogo from "@components/header/HeaderTripadvisorLogo";
import PhoneIcon from "@components/icons/PhoneIcon";
import { ITopNavigationResponse } from "@customTypes/commonResponses";
import HeaderPhoneButton from "./HeaderPhoneButton";

interface IHeaderContactProfileProps {
  phoneAction: string;
  phoneNumber: string;
  topNavigation: ITopNavigationResponse;
}

const HeaderContactProfile = ({
                                phoneAction,
                                phoneNumber,
                                topNavigation,
                              }: IHeaderContactProfileProps): JSX.Element => {
  return (
    <div className="flex w-fit flex-row justify-end desktop:gap-3">
      {topNavigation?.TripAdvisor_Rating && (
        <HeaderTripadvisorLogo
          isDesktop={true}
          tripadvisorLogo={topNavigation?.TripAdvisor_Rating}
        />
      )}
      <HeaderPhoneButton phoneAction={phoneAction} phoneNumber={phoneNumber}>
        <div className="relative h-[1.25rem] w-[1.25rem]">
          <PhoneIcon color='red'/>
        </div>
      </HeaderPhoneButton>
    </div>
  );
};
export default HeaderContactProfile;
