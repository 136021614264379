import BurgerMenu from "@components/icons/BurgerMenu";
import { IMenuItemResponse } from "@customTypes/commonResponses";
import HeaderMenuItem from "./HeaderMenuItem";
import useAppContext from '@hooks/useAppContext'

interface IHeaderMenuItemsProps {
  menuItems: IMenuItemResponse[];
}

const HeaderMenuItems = ({ menuItems }: IHeaderMenuItemsProps): JSX.Element => {
  const { isMobile } = useAppContext();
  return (
    <>
      <div className="desktop:hidden">
        <BurgerMenu/>
      </div>
      <nav aria-label="Site Menu" className="hidden desktop:flex" id="navbar">
        <ul className="flex list-none" role="menu">
          {menuItems.map((menuItem, index) => {
            return menuItem.title !== undefined ? (
              <HeaderMenuItem item={menuItem} key={index} index={index}/>
            ) : null;
          })}
        </ul>
      </nav>
    </>
  );
};

export default HeaderMenuItems;
