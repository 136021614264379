type Tchildren = JSX.Element

interface IConditionalWrapper {
  condition?: boolean
  children: Tchildren
  wrapper: (children: Tchildren) => JSX.Element
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: IConditionalWrapper): JSX.Element => {
  return condition ? wrapper(children) : children
}

export default ConditionalWrapper
