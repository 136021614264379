import searchManagementTabsGenerator from "@components/SearchManagement/SearchManagementTabsGenerator";
import {
    BUDGETS,
    LOCAL_STORAGE,
    TAG_TYPES,
    TRAVELERS,
} from "@components/utils/constants";
import { sanitizeSearchManagement } from "@components/utils/filterHelpers";
import {
    bodyParamsToSearchQueryObject,
    budgetParamsToSearchQueryObject,
} from "@components/utils/filterProviderHelpers";
import useCalendar, { IUseCalendarReturn } from "@hooks/useCalendar";
import useHolidayDuration from "@hooks/useHolidayDuration";
import usePreferencesSync from "@hooks/usePreferencesSync";
import useSearch from "@hooks/useSearch";
import { INITIAL_FORM_VALUE } from "@libs/forms/utils/constants";
import { useRouter } from "next/router";
import React, {
    Dispatch,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IDefaultPagePropsResponse } from "../types/commonResponses";

import {
    IBodyParams,
    IOpenedTab,
    IPropertyCategory,
    IQueryItemAccommodation,
    IQueryItemActivityType,
    IQueryItemExperience,
    IQueryItemLocationType,
    ISearchManagement,
    ISearchManagementTab,
    ISearchQuery,
    TAccommodationTypes,
} from "../types/filtersProvider";

export interface IFiltersContext {
  show: boolean;
  isPartialFilter: boolean;
  openedTab: IOpenedTab;
  searchQuery: ISearchQuery;
  searchManagement: ISearchManagement | null;
  searchManagementTabs: ISearchManagementTab[];
  openFilterModal: (tabIndex?: number, shouldScroll?: boolean) => void;
  closeFilterModal: () => void;
  closeAccordion: (tabIndex: number) => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<ISearchQuery>>;
  selectExperience: (experience: IQueryItemExperience) => void;
  removeExperience: () => void;
  selectLocation: (location: IQueryItemLocationType) => void;
  removeLocation: (location: IQueryItemLocationType) => void;
  selectActivity: (activity: IQueryItemActivityType) => void;
  removeActivity: (activity: IQueryItemActivityType) => void;
  selectAccommodation: (accommodation: IQueryItemAccommodation) => void;
  removeAccommodation: (accommodation: IQueryItemAccommodation) => void;
  changeAccommodationCategory: (
    category: IPropertyCategory<"Hotel" | "Rental">
  ) => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  updateBudget: (min: number, max: number) => void;
  globalSearch: { value: boolean; hasSelected: boolean };
  setGlobalSearch: React.Dispatch<
    React.SetStateAction<{
      value: boolean;
      hasSelected: boolean;
    }>
  >;
  calendarUtils: IUseCalendarReturn;
  clearAllValues: () => void;
  initiateCleanSearch: () => void;
  initiateSearch: () => void;
  convertQueryParamsToSearchQuery: (bodyParams: IBodyParams) => void;
  convertBudgetParamsToSearchQuery: (bodyParams: IBodyParams) => void;
  updateTravelers: (
    name: "rooms" | "children" | "adults" | "teens",
    value: number,
    minValue: number,
    maxValue: number
  ) => void;
  changeCalendarSelection: (state: boolean) => void;
  syncFilterBar: () => void;
  isSearchShowingClosestResults: boolean;
  setIsSearchShowingClosestResults: Dispatch<React.SetStateAction<boolean>>;
}

const FiltersContext = createContext<IFiltersContext | null>(null);

interface IFiltersProvider {
  children: JSX.Element | JSX.Element[];
  pageProps: IDefaultPagePropsResponse;
}

const FiltersProvider = ({
  children,
  pageProps,
}: IFiltersProvider): JSX.Element => {
  const [isSearchShowingClosestResults, setIsSearchShowingClosestResults] =
    useState(false);
  const router = useRouter();

  usePreferencesSync();

  const initialTabState = {
    experiences: true,
    calendar: false,
    locationTypes: true,
    accommodations: true,
    activityTypes: true,
  };

  const initialSearchQueryState: ISearchQuery = {
    experiences: [],
    locationTypes: [],
    accommodations: {
      budget: {
        min: BUDGETS.MIN,
        max: BUDGETS.MAX,
        isTouched: false,
      },
      hotels: { categories: [], isChecked: false, title: "Hotel" },
      rentals: { categories: [], isChecked: false, title: "Rental" },
    },
    activityTypes: [],
    travelers: { rooms: INITIAL_FORM_VALUE.rooms },
  };

  const [show, setShow] = useState(false);
  const [isPartialFilter, setIsPartialFilter] = useState(false);
  const [openedTab, setOpenedTab] = useState(initialTabState);
  const [searchQuery, setSearchQuery] = useState<ISearchQuery>(
    initialSearchQueryState
  );
  const [searchManagement, setSearchManagement] =
    useState<ISearchManagement | null>(null);
  const [searchManagementTabs, setSearchManagementTabs] = useState<
    ISearchManagementTab[]
  >([]);
  const [globalSearch, setGlobalSearch] = useState({
    value: false,
    hasSelected: false,
  });
  const [tabToScrollTo, setTabToScrollTo] = useState<number | null>(null);

  useEffect(() => {
    setIsPartialFilter(router.asPath === "/" || globalSearch.value);
  }, [router, globalSearch]);

  const {
    showCalendar,
    setShowCalendar,
    calendarStatus,
    setCalendarStatus,
    inputDate,
    setInputDate,
    monthsShown,
    setMonthsShown,
    startDateState,
    setStartDate,
    endDateState,
    setEndDate,
    hasLoadedOnce,
    setHasLoadedOnce,
    resetToLastValues,
    resetValues,
    numberOfNights,
    setShouldCleanValues,
  } = useCalendar();

  const calendarUtils = useMemo(() => {
    return {
      showCalendar,
      setShowCalendar,
      calendarStatus,
      setCalendarStatus,
      inputDate,
      setInputDate,
      monthsShown,
      setMonthsShown,
      startDateState,
      setStartDate,
      endDateState,
      setEndDate,
      hasLoadedOnce,
      setHasLoadedOnce,
      resetToLastValues,
      resetValues,
      numberOfNights,
      setShouldCleanValues,
    };
  }, [
    showCalendar,
    setShowCalendar,
    calendarStatus,
    setCalendarStatus,
    inputDate,
    setInputDate,
    monthsShown,
    setMonthsShown,
    startDateState,
    setStartDate,
    endDateState,
    setEndDate,
    hasLoadedOnce,
    setHasLoadedOnce,
    resetToLastValues,
    resetValues,
    numberOfNights,
  ]);

  const { buildQueryParameters } = useSearch();
  const { useDurationRedirect, shouldRedirect } = useHolidayDuration(
    startDateState,
    endDateState
  );

  const saveLocalStorage = (
    saveData: ISearchQuery,
    isCleaningDates?: boolean
  ): void => {
    localStorage.setItem(
      LOCAL_STORAGE.userSearchFilters,
      JSON.stringify({
        ...saveData,
      })
    );
    localStorage.setItem(
      LOCAL_STORAGE.userSearchQueryParams,
      localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams)
        ? JSON.stringify({
            ...JSON.parse(
              // @ts-expect-error
              localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams)
            ),
            startDate: isCleaningDates ? null : startDateState,
            endDate: isCleaningDates ? null : endDateState,
          })
        : JSON.stringify({
            startDate: isCleaningDates ? null : startDateState,
            endDate: isCleaningDates ? null : endDateState,
          })
    );
    if (shouldRedirect) {
      resetToLastValues();
    }
  };

  const syncOpenedTabs = (shouldSetSearchQuery?: boolean): void => {
    const loadedFiltersState = JSON.parse(
      // @ts-expect-error
      localStorage.getItem(LOCAL_STORAGE.userSearchFilters)
    );
    if (shouldSetSearchQuery) {
      if (loadedFiltersState) {
        setSearchQuery(loadedFiltersState);
      } else {
        setSearchQuery(initialSearchQueryState);
      }
    }
    setOpenedTab({
      experiences: loadedFiltersState
        ? loadedFiltersState.experiences?.length === 0
        : initialSearchQueryState.experiences?.length === 0,
      calendar: false,
      locationTypes: loadedFiltersState
        ? loadedFiltersState.locationTypes?.length === 0
        : initialSearchQueryState.locationTypes.length === 0,
      accommodations: loadedFiltersState
        ? loadedFiltersState.accommodations?.hotels?.categories?.length === 0 &&
          loadedFiltersState.accommodations?.rentals?.categories?.length ===
            0 &&
          !loadedFiltersState.accommodations?.hotels?.isChecked &&
          !loadedFiltersState.accommodations?.rentals?.isChecked &&
          loadedFiltersState.accommodations?.budget?.min === BUDGETS.MIN &&
          loadedFiltersState.accommodations?.budget?.max === BUDGETS.MAX &&
          loadedFiltersState.travelers?.rooms === TRAVELERS.ROOMS.DEFAULT
        : initialSearchQueryState.accommodations.hotels.categories.length ===
            0 &&
          initialSearchQueryState.accommodations.rentals.categories.length ===
            0,
      activityTypes: loadedFiltersState
        ? loadedFiltersState.activityTypes?.length === 0
        : initialSearchQueryState.activityTypes?.length === 0,
    });
  };

  const loadLocalStorage = (): void => {
    syncOpenedTabs(true);
    calendarUtils.resetToLastValues();
  };

  const syncFilterBar = (): void => {
    const loadedFiltersState = JSON.parse(
      // @ts-expect-error
      localStorage.getItem(LOCAL_STORAGE.userSearchFilters)
    );

    if (!loadedFiltersState) {
      saveLocalStorage(initialSearchQueryState);
    } else {
      loadLocalStorage();
      const { startDate, endDate } = router.query;
      populateSearchManagement(searchManagement, loadedFiltersState, {
        startDate,
        endDate,
      });
    }
  };

  useEffect(() => {
    // Initial population of searchQuery and populating filterBar initial load on refres and on searchManagement changes;
    syncFilterBar();
  }, [searchManagement]);

  useEffect(() => {
    if (!show) {
      syncOpenedTabs();
    }
  }, [show]);

  const openFilterModal = (tabIndex?: number, shouldScroll?: boolean): void => {
    const tabs = Object.keys(openedTab);
    setShow(true);
    if (tabIndex || tabIndex === 0) {
      setOpenedTab((prevState) => {
        return {
          ...prevState,
          experiences: globalSearch.value ? false : prevState.experiences,
          [tabs[tabIndex]]: true,
        };
      });
      if (shouldScroll) {
        setTabToScrollTo(tabIndex);
      }
    }
  };

  useEffect(() => {
    if (tabToScrollTo !== null) {
      const tabs = Object.keys(openedTab);
      const el = document.querySelector(`#${tabs[tabToScrollTo]}`);
      el?.scrollIntoView(true);
    }
    setTabToScrollTo(null);
  }, [tabToScrollTo]);

  const closeAccordion = (tabIndex: number): void => {
    const tabs = Object.keys(openedTab);
    setOpenedTab((prevState) => {
      return {
        ...prevState,
        [tabs[tabIndex]]: false,
      };
    });
  };

  const closeFilterModal = (): void => {
    setShow(false);
    setGlobalSearch({ value: false, hasSelected: false });
    loadLocalStorage();
  };

  const selectExperience = (experience: IQueryItemExperience): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        experiences: [
          {
            id: experience.id,
            isChecked: true,
            title: experience.title,
            type: TAG_TYPES.TAG,
          },
        ],
      };
    });
  };

  const removeExperience = (): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        experiences: [],
      };
    });
  };

  const selectLocation = (location: IQueryItemLocationType): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        locationTypes: [
          ...prevState.locationTypes,
          {
            id: location.id,
            isChecked: true,
            title: location.title,
            type: TAG_TYPES.LOCATION_TYPE,
          },
        ],
      };
    });
  };

  const removeLocation = (location: IQueryItemLocationType): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        locationTypes: prevState.locationTypes.filter(
          (locationItem) => locationItem.id !== location.id
        ),
      };
    });
  };

  const selectActivity = (activity: IQueryItemActivityType): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        activityTypes: [
          ...prevState.activityTypes,
          {
            id: activity.id,
            isChecked: true,
            title: activity.title,
            type: TAG_TYPES.ACTIVITY_TYPE,
          },
        ],
      };
    });
  };

  const removeActivity = (activity: IQueryItemActivityType): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        activityTypes: prevState.activityTypes.filter(
          (activityItem) => activityItem.id !== activity.id
        ),
      };
    });
  };

  const changeAccommodationCategory = (
    category: IPropertyCategory<"Hotel" | "Rental">
  ): void => {
    let categoryType: TAccommodationTypes;
    switch (category.title) {
      case "Hotel":
        categoryType = "hotels";
        break;
      case "Rental":
        categoryType = "rentals";
        break;
    }

    setSearchQuery((prevState) => {
      return {
        ...prevState,
        accommodations: {
          ...prevState.accommodations,
          [categoryType]: {
            ...prevState.accommodations[categoryType],
            isChecked: !prevState.accommodations[categoryType].isChecked,
          },
        },
      };
    });
  };

  const selectAccommodation = (
    accommodation: IQueryItemAccommodation
  ): void => {
    let accommodationType: TAccommodationTypes;
    switch (accommodation.propertyCategory) {
      case "Hotel":
        accommodationType = "hotels";
        break;
      case "Rental":
        accommodationType = "rentals";
        break;
    }

    setSearchQuery((prevState) => {
      return {
        ...prevState,
        accommodations: {
          ...prevState.accommodations,
          [accommodationType]: {
            ...prevState.accommodations[accommodationType],
            categories: [
              ...prevState.accommodations[accommodationType].categories,
              {
                id: accommodation.id,
                isChecked: true,
                title: accommodation.title,
                propertyCategory: accommodation.propertyCategory,
              },
            ],
          },
        },
      };
    });
  };

  const removeAccommodation = (
    accommodation: IQueryItemAccommodation
  ): void => {
    let accommodationType: TAccommodationTypes;
    switch (accommodation.propertyCategory) {
      case "Hotel":
        accommodationType = "hotels";
        break;
      case "Rental":
        accommodationType = "rentals";
        break;
    }
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        accommodations: {
          ...prevState.accommodations,
          [accommodationType]: {
            ...prevState.accommodations[accommodationType],
            categories: [
              ...prevState.accommodations[accommodationType].categories.filter(
                (accommodationItem) => accommodationItem.id !== accommodation.id
              ),
            ],
          },
        },
      };
    });
  };

  const updateBudget = (min: number, max: number): void => {
    setSearchQuery((prevState) => {
      return {
        ...prevState,
        accommodations: {
          ...prevState.accommodations,
          budget: {
            min,
            max,
            isTouched: true,
          },
        },
      };
    });
  };

  const clearAllValues = useCallback(() => {
    setSearchQuery(initialSearchQueryState);
    calendarUtils.resetValues();
    saveLocalStorage(initialSearchQueryState, true);
  }, []);

  const updateTravelers = (
    name: "rooms" | "children" | "adults" | "teens",
    value: number,
    minValue: number,
    maxValue: number
  ): void => {
    if (value > minValue && value < maxValue) {
      setSearchQuery((prevState) => {
        return {
          ...prevState,
          travelers: { ...prevState.travelers, [name]: value },
        };
      });
    }
  };

  const query = useMemo(() => {
    return buildQueryParameters(searchQuery, searchManagement);
  }, [buildQueryParameters, searchQuery, searchManagement]);

  const populateSearchManagement = (
    sanitizedSearchManagementRes: ISearchManagement | null,
    urlSearchQuery?: ISearchQuery | null,
    urlDates?: {
      startDate?: string | string[];
      endDate?: string | string[];
    }
  ): void => {
    const searchManagementState =
      sanitizedSearchManagementRes ?? searchManagement;
    if (searchManagementState !== null) {
      setSearchManagementTabs(
        searchManagementTabsGenerator(
          searchManagementState,
          openFilterModal,
          urlSearchQuery ?? searchQuery,
          urlDates
        )
      );
    }
  };

  const convertQueryParamsToSearchQuery = (bodyParams: IBodyParams): void => {
    if (searchManagement) {
      const urlSearchQuery = bodyParamsToSearchQueryObject(
        bodyParams,
        searchManagement,
        initialSearchQueryState
      );
      setSearchQuery(urlSearchQuery);
      saveLocalStorage(urlSearchQuery);
    }
  };

  const convertBudgetParamsToSearchQuery = (bodyParams: IBodyParams): void => {
    const urlSearchQuery = budgetParamsToSearchQueryObject(
      bodyParams,
      searchQuery
    );
    setSearchQuery(urlSearchQuery);
    saveLocalStorage(urlSearchQuery);
  };

  const initiateCleanSearch = async (): Promise<void> => {
    await router.push("/package-search");
    setShow(false);
    setGlobalSearch({ value: false, hasSelected: false });
  };

  const initiateSearch = async (): Promise<void> => {
    setShouldCleanValues(false);
    saveLocalStorage(searchQuery);
    shouldRedirect
      ? await useDurationRedirect()
      : await router.push(
          `/package-search${query || ""}${
            query
              ? startDateState && endDateState
                ? "&"
                : ""
              : startDateState
              ? "?"
              : ""
          }${
            startDateState && endDateState
              ? "startDate=" + startDateState.format("YYYY-MM-DD")
              : ""
          }${
            startDateState && endDateState
              ? "&endDate=" + endDateState.format("YYYY-MM-DD")
              : ""
          }`
        );
    setIsSearchShowingClosestResults(false);
    setShow(false);
    setGlobalSearch({ value: false, hasSelected: false });
    const { startDate, endDate } = router.query;
    populateSearchManagement(searchManagement, null, { startDate, endDate });
  };

  useEffect(() => {
    if (pageProps?.searchManagement) {
      try {
        const searchManagementRes = pageProps.searchManagement;

        const sanitizedSearchManagementRes =
          sanitizeSearchManagement(searchManagementRes);

        setSearchManagement(sanitizedSearchManagementRes);
      } catch (err) {
        console.error(err);
      }
    }
  }, [pageProps?.searchManagement]);

  const changeCalendarSelection = (state: boolean): void => {
    setSearchManagementTabs((prevState) => {
      const newState = [...prevState];
      newState[1].isChecked = state;
      return newState;
    });
  };

  const providerValues = useMemo(
    () => ({
      show,
      isPartialFilter,
      openedTab,
      searchQuery,
      searchManagement,
      searchManagementTabs,
      openFilterModal,
      closeFilterModal,
      closeAccordion,
      setSearchQuery,
      selectExperience,
      removeExperience,
      selectLocation,
      removeLocation,
      selectActivity,
      removeActivity,
      globalSearch,
      setGlobalSearch,
      setShow,
      initiateSearch,
      initiateCleanSearch,
      selectAccommodation,
      removeAccommodation,
      changeAccommodationCategory,
      updateBudget,
      updateTravelers,
      calendarUtils,
      clearAllValues,
      convertQueryParamsToSearchQuery,
      convertBudgetParamsToSearchQuery,
      changeCalendarSelection,
      syncFilterBar,
      isSearchShowingClosestResults,
      setIsSearchShowingClosestResults,
    }),
    [
      show,
      isPartialFilter,
      openedTab,
      searchQuery,
      searchManagement,
      searchManagementTabs,
      globalSearch,
      calendarUtils,
      isSearchShowingClosestResults,
    ]
  );

  return (
    <FiltersContext.Provider value={providerValues}>
      {children}
    </FiltersContext.Provider>
  );
};

export { FiltersContext, FiltersProvider };

