
interface ICheckMark {
  color: string;
}

interface Map {
  [key: string]: string;
}

const COLORS: Map = {
  lightblue: "#10247E",
  white: "#FFFFFF",
  default: "#010202",
};

const CheckMark = ({ color }: ICheckMark): JSX.Element => {
  return (
    <div className="mx-auto">
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.67461"
          y1="6.15084"
          x2="5.55045"
          y2="9.32484"
          stroke={COLORS[color ?? "default"]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="0.75"
          y1="-0.75"
          x2="9.32088"
          y2="-0.75"
          transform="matrix(0.64884 -0.760925 0.802502 0.596649 5.70575 10.3843)"
          stroke={COLORS[color ?? "default"]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
export default CheckMark;
