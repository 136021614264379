
function ExitButton({ onClick, showBackground = true }: any): JSX.Element {
  return (
    <div>
      <button
        className={`h-[2.75rem] w-[2.75rem] rounded-2xl ${showBackground ? 'bg-orange-10' : ''}`}
        onClick={() => onClick(false)}
        aria-label="Close"
        title="Close"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto"
        >
          <path
            d="M1.91357 11.6853L12.2845 1.3144"
            stroke="#7E7E7E"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
          <path
            d="M12.2847 11.6853L1.91377 1.3144"
            stroke="#7E7E7E"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
  );
}

export default ExitButton;
