
const MinusButton = ({ onClick }: any): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className="mr-2 flex h-[36px] w-[36px] items-center rounded-xl bg-white px-[0.771rem] py-[1.125rem]"
      aria-label="Minus"
      title="Minus"
    >
      <svg
        width="13"
        height="2"
        viewBox="0 0 13 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.887814 1.00013L11.985 0.951066"
          stroke="#424242"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export default MinusButton;
