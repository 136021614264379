import { LOCAL_STORAGE } from "@components/utils/constants";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState, } from "react";
import useFormatDate from "./useFormatDate";
import dayjs, { Dayjs } from 'dayjs'

export interface IUseCalendarReturn {
  showCalendar: boolean;
  setShowCalendar: Dispatch<SetStateAction<boolean>>;
  calendarStatus: boolean;
  setCalendarStatus: Dispatch<SetStateAction<boolean>>;
  inputDate: string | null;
  setInputDate: Dispatch<SetStateAction<string | null>>;
  monthsShown: number;
  setMonthsShown: Dispatch<SetStateAction<number>>;
  startDateState: Dayjs | null;
  setStartDate: Dispatch<SetStateAction<Dayjs | null>>;
  endDateState: Dayjs | null;
  setEndDate: Dispatch<SetStateAction<Dayjs | null>>;
  hasLoadedOnce: boolean;
  setHasLoadedOnce: Dispatch<SetStateAction<boolean>>;
  resetToLastValues: () => void;
  resetValues: () => void;
  numberOfNights: number | null;
  setShouldCleanValues: Dispatch<SetStateAction<boolean>>;
}

const useCalendar = (): IUseCalendarReturn => {
  const router = useRouter();
  const { startDate, endDate } = router.query;
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarStatus, setCalendarStatus] = useState(false);
  const [inputDate, setInputDate] = useState<string | null>(null);
  const [monthsShown, setMonthsShown] = useState(2);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [shouldCleanValues, setShouldCleanValues] = useState(false);
  const [startDateState, setStartDate] = useState<Dayjs | null>(
    startDate
      ? dayjs(String(startDate).replaceAll(" ", "+"))
      : typeof window !== "undefined" &&
      JSON.parse(
        String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
      )?.startDate
        ? localStorage.getItem("USER_REQUEST_QUOTE")
          ? dayjs(
            JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
              .startDate
          )
          : dayjs(
            JSON.parse(
              String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
            ).startDate
          )
        : null
  );
  const [endDateState, setEndDate] = useState<Dayjs | null>(
    endDate
      ? dayjs(String(endDate).replaceAll(" ", "+"))
      : typeof window !== "undefined" &&
      JSON.parse(
        String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
      )?.endDate
        ? localStorage.getItem("USER_REQUEST_QUOTE")
          ? dayjs(
            JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
              .endDate
          )
          : dayjs(
            JSON.parse(
              String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
            ).endDate
          )
        : null
  );

  const resetToLastValues = useCallback(() => {
    const loadedStartDate = startDate
      ? dayjs(String(startDate).replaceAll(" ", "+"))
      : typeof window !== "undefined" &&
      JSON.parse(
        String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
      )?.startDate
        ? localStorage.getItem("USER_REQUEST_QUOTE")
          ? dayjs(
            JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
              .startDate
          )
          : dayjs(
            JSON.parse(
              String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
            ).startDate
          )
        : null;
    const loadedEndDate = endDate
      ? dayjs(String(endDate).replaceAll(" ", "+"))
      : typeof window !== "undefined" &&
      JSON.parse(
        String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
      )?.endDate
        ? localStorage.getItem("USER_REQUEST_QUOTE")
          ? dayjs(
            JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
              .endDate
          )
          : dayjs(
            JSON.parse(
              String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
            ).endDate
          )
        : null;
    setStartDate(loadedStartDate);
    setEndDate(loadedEndDate);
    setMonthsShown(2);
    setInputDate(null);
  }, [startDate, setStartDate, endDate, setEndDate, setMonthsShown]);

  const resetValues = (): void => {
    setInputDate(null);
    setStartDate(null);
    setEndDate(null);
    setShouldCleanValues(true);
    localStorage.setItem(
      LOCAL_STORAGE.userSearchQueryParams,
      JSON.stringify({ startDate: null, endDate: null })
    );
  };

  useEffect(() => {
    if (shouldCleanValues) {
      resetValues();
      setShouldCleanValues(false);
    }
  }, [router.query]);

  const numberOfNights = useMemo<number | null>(() => {
    if (startDateState && endDateState) {
      return endDateState.diff(startDateState, "days");
    }
    return null;
  }, [startDateState, endDateState])

  useFormatDate(setInputDate, [startDateState, endDateState], numberOfNights);

  return {
    showCalendar,
    setShowCalendar,
    calendarStatus,
    setCalendarStatus,
    inputDate,
    setInputDate,
    monthsShown,
    setMonthsShown,
    startDateState,
    setStartDate,
    endDateState,
    setEndDate,
    hasLoadedOnce,
    setHasLoadedOnce,
    resetToLastValues,
    resetValues,
    numberOfNights,
    setShouldCleanValues,
  };
};

export default useCalendar;
