import ServerErrorComponent from "@components/pages/page/components/ServerErrorComponent";
import baseApiCalls from "@services/baseApiCalls";
import { GetStaticProps } from "next";

const ServerErrorPage = (): JSX.Element => {
  return <ServerErrorComponent cf={false} />;
};

export default ServerErrorPage;

export const getStaticProps: GetStaticProps = async () => {
  const {
    footer,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp,
  } = await baseApiCalls();

  return {
    props: {
      footer,
      mediaAssets,
      topNavigation,
      menus,
      usp,
      searchManagement: searchManagementRes,
    },
  };
};
