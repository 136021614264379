import Button from "@components/common/Button";
import ButtonsWrapper from "@components/common/ButtonsWrapper";
import ConfirmationModal from "@components/common/ConfirmationModal";
import { BUDGETS, TRAVELERS } from "@components/utils/constants";
import useAppContext from "@hooks/useAppContext";
import { generateNumberOfNightsText } from "@hooks/useFormatDate";
import useScreenType from "@hooks/useScreenType";
import FormModal from "@modules/requestAQuote/FormModal";
import { FiltersContext } from "providers/FiltersProvider";
import { useContext, useMemo, useState } from "react";
import FilterModalAccordionItem from "./FilterModalAccordionItem";
import ImagesRows from "./ImagesRows";

const ELEMENT_HEIGHTS = {
  header: 44,
  button: 85,
};

const MAX_FILTER_WIDTH = 800;

const FilterModal = (): JSX.Element => {
  const appContext = useAppContext();
  const filtersContext = useContext(FiltersContext);
  const screenType = useScreenType();

  const [showClearWarning, setShowClearWarning] = useState(false);
  const [hasClearedAll, setHasClearedAll] = useState(false);

  // The modal is freezed and background is not scrollable even without the hook hence commented out
  // It had to be removed for NAM-2432, to be removed if no bug related to modal freeze is observed
  // useModalScrollFreeze([filtersContext.show]);

  const hasClearAllButton = useMemo(() => {
    if (filtersContext?.calendarUtils?.inputDate) {
      return true;
    } else {
      const searchQuery = filtersContext?.searchQuery;
      if (searchQuery) {
        if (filtersContext?.isPartialFilter) {
          return searchQuery?.experiences?.length > 0;
        } else {
          return (
            searchQuery?.experiences?.length > 0 ||
            searchQuery?.locationTypes?.length > 0 ||
            searchQuery?.activityTypes?.length > 0 ||
            // All accommodation checks below
            searchQuery?.accommodations?.hotels?.isChecked ||
            searchQuery?.accommodations?.rentals?.isChecked ||
            searchQuery?.accommodations?.hotels?.categories?.length > 0 ||
            searchQuery?.accommodations?.rentals?.categories?.length > 0 ||
            searchQuery?.accommodations?.budget.min !== BUDGETS.MIN ||
            searchQuery?.accommodations?.budget.max !== BUDGETS.MAX ||
            searchQuery?.travelers?.rooms !== TRAVELERS.ROOMS.DEFAULT
          );
        }
      }
    }
  }, [
    filtersContext?.isPartialFilter,
    filtersContext?.searchQuery,
    filtersContext?.calendarUtils,
  ]);

  const confirmHandler = (): void => {
    filtersContext?.clearAllValues();
    setHasClearedAll(true);
    setShowClearWarning(false);
  };

  const closeHandler = (): void => {
    const closeHandlerFunction = async (): Promise<void> => {
      if (hasClearedAll) {
        if (!filtersContext?.isPartialFilter) {
          await filtersContext?.clearAllValues();
          await filtersContext?.initiateCleanSearch();
        }
        setHasClearedAll(false);
      }
      filtersContext?.closeFilterModal();
    };
    closeHandlerFunction().catch(() => {
    });
  };

  return (
    <>
      {filtersContext?.show && (
        <>
          <FormModal
            title={
              filtersContext?.isPartialFilter
                ? "Find Your Trip"
                : "Trip Search Filters"
            }
            closeModalHandler={closeHandler}
            maxDesktopWidth={MAX_FILTER_WIDTH}
            isFilterModal={true}
            zIndex={1000}
          >
            <div
              id="scroll-container"
              className={`${
                !filtersContext.globalSearch.value ||
                filtersContext.globalSearch.hasSelected
                  ? "bg-blue-5"
                  : ""
              } flex flex-col desktop:bg-white ${
                filtersContext.isPartialFilter ? "" : "pb-[100px]"
              } overflow-auto desktop:mb-0 desktop:h-fit desktop:px-[98px] desktop:pb-0`}
              // Dynamic height because of iPhone Safari browser when addressbar is expanded, modal content is hidden behind button);
              style={{
                height: appContext?.isMobile
                  ? screenType.size.height - ELEMENT_HEIGHTS.header
                  : undefined,
              }}
            >
              {filtersContext.globalSearch.value &&
              !filtersContext.globalSearch.hasSelected ? (
                <div className="px-5 desktop:px-0">
                  {filtersContext.searchManagement?.Experiences_Long_Title && (
                    <h6 className="my-2 text-h6 font-medium desktop:my-3 ">
                      {filtersContext.searchManagement.Experiences_Long_Title}
                    </h6>
                  )}
                  {filtersContext.searchManagement?.Experiences_Text && (
                    <p className="mb-4 text-p">
                      {filtersContext.searchManagement.Experiences_Text}
                    </p>
                  )}
                  <ImagesRows variant={"stepOne"}/>
                </div>
              ) : (
                filtersContext.searchManagementTabs.map((tab, index) => {
                  if (filtersContext.isPartialFilter && index > 1) {
                    return undefined;
                  } else {
                    return (
                      <FilterModalAccordionItem
                        key={index}
                        tab={tab}
                        tabSearchQuery={
                          // @ts-expect-error
                          filtersContext?.searchQuery[tab.tabName]
                        }
                        // @ts-expect-error
                        showAccordion={filtersContext?.openedTab[tab.tabName]}
                        isLastItem={
                          filtersContext.isPartialFilter
                            ? index === 1
                            : index === 4
                        }
                      />
                    );
                  }
                })
              )}
            </div>
            <>
              {(!filtersContext.globalSearch.value ||
                filtersContext.globalSearch.hasSelected) && (
                <div className="fixed left-0 bottom-0 z-[100] mx-auto flex h-fit w-[calc(100vw)] flex-col items-center desktop:static desktop:my-6 desktop:w-[350px] desktop:bg-transparent">
                  <ButtonsWrapper>
                    {filtersContext.openedTab.calendar &&
                    appContext.isMobile ? (
                      filtersContext.calendarUtils.inputDate && (
                        <Button
                          id={"clear-calendar-btn"}
                          variant="no-outline"
                          onClick={() => {
                            filtersContext.calendarUtils.resetValues();
                            filtersContext.changeCalendarSelection(false);
                          }}
                        >
                          Clear&nbsp;Dates
                        </Button>
                      )
                    ) : hasClearAllButton ? (
                      <>
                        <Button
                          id={"clear-filter-btn"}
                          variant="no-outline"
                          onClick={() => setShowClearWarning(true)}
                        >
                          Clear&nbsp;All
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      id={"apply-filter-btn"}
                      variant="primary"
                      onClick={() => {
                        (async () => {
                          await filtersContext.initiateSearch();
                        })().catch(() => {
                        });
                      }}
                    >
                      {`Apply${generateNumberOfNightsText(
                        filtersContext?.calendarUtils.numberOfNights,
                        "\xa0"
                      )}`}
                    </Button>
                  </ButtonsWrapper>
                </div>
              )}
            </>
            <>
              {showClearWarning && (
                <ConfirmationModal
                  cancelHandler={() => setShowClearWarning(false)}
                  confirmHandler={confirmHandler}
                />
              )}
            </>
          </FormModal>
        </>
      )}
    </>
  );
};

export default FilterModal;
