import MediaAssets from '@components/utils/MediaAssets'
import Image from 'next/legacy/image'
import BackButton from '@components/icons/BackButton'
import ExitButton from '@components/icons/ExitButton'

interface IModalHeader {
  title: string
  subtitle?: string
  hasLogo?: boolean
  onBackHandler?: () => void
  onCloseHandler?: () => void
  isFilterModal?: boolean
}

const ModalHeader = ({
  title,
  subtitle,
  hasLogo,
  onBackHandler,
  onCloseHandler,
  isFilterModal,
}: IModalHeader): JSX.Element => {
  return (
    <div
      className={`relative flex shrink-0 flex-row items-center overflow-hidden desktop:mx-8 ${
        isFilterModal
          ? 'mx-[0.875rem] my-[0.3125rem] desktop:my-8'
          : `mx-5 mt-4 bg-[#F3F4F8] ${!!subtitle ? 'h-[65px]' : 'h-[48px]'}`
      }`}
    >
      {hasLogo && (
        <div className='mr-auto h-fit w-fit'>
          <Image
            src={MediaAssets.getAsset('frog_request_quote')}
            width='39'
            height='48'
            layout='fixed'
            alt='Request a quote'
          />
        </div>
      )}
      {onBackHandler && !hasLogo && (
        <div
          className={`mr-auto ${!isFilterModal ? `${!!subtitle ? 'mb-[40px]' : 'mb-[20px]'} -ml-[10px] ` : ''}h-fit w-fit z-[1]`}
        >
          <BackButton onClick={onBackHandler} showBackground={false} />
        </div>
      )}
      {onCloseHandler && (
        <div
          className={`ml-auto mr-0 ${!isFilterModal ? `${!!subtitle ? 'mb-[40px]' : 'mb-[20px]'} -mr-[10px] ` : ''}h-fit w-fit z-[1]`}
        >
          <ExitButton onClick={onCloseHandler} showBackground={false} />
        </div>
      )}
      <div className='absolute w-full h-full items-center'>
        <div className='mx-auto flex w-fit flex-col items-center gap-2'>
          <span
            className={`${isFilterModal ? 'text-p font-medium desktop:text-h5' : 'text-h3 font-bold h-[20px]'}`}
          >
            {title}
          </span>
          {subtitle && <p className='text-p text-[#364152]'>{subtitle}</p>}
        </div>
      </div>
    </div>
  )
}

export default ModalHeader
