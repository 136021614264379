import CloseButton from "@components/common/CloseButton";

interface IToast {
  onClose: () => void;
  text: string;
  position: string;
}

const Toast = ({ onClose, text, position = "static" }: IToast): JSX.Element => {
  switch (position) {
    case "static":
      return (
        <div className="z-50 mb-4 flex justify-between rounded-3xl bg-blue-10 py-[0.125rem]">
          <p className="text-'p-large' my-auto ml-8 text-black ">{text}</p>
          <div className="my-auto mr-[-0.125rem]">
            <CloseButton onClick={onClose} />
          </div>
        </div>
      );
    case "fixed":
      return (
        <div className="fixed top-[150px] left-1/2 z-50 mb-4 flex w-[80%] -translate-x-1/2 justify-between rounded-3xl bg-blue-10 py-[0.125rem] desktop:max-w-[880px]">
          <p className="text-'p-large' my-auto ml-8 text-black ">{text}</p>
          <div className="my-auto mr-[-0.125rem]">
            <CloseButton onClick={onClose} />
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export default Toast;
