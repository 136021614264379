import { slashBeforeUrlAdder } from "@components/utils/urlHelpers";
import { PAGE_TYPES } from "@customTypes/index";
import useAppContext from "@hooks/useAppContext";
import baseApiCalls from "@services/baseApiCalls";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

const RedirectingPage = (): JSX.Element => {
  const appContext = useAppContext();
  const router = useRouter();

  useEffect(() => {
    const signInOutRedirectFunction = async (): Promise<void> => {
      const signInRedirectUrl = localStorage.getItem("REDIRECT_AFTER_LOGIN");
      const signOutRedirectUrl = localStorage.getItem(
        "REDIRECT_AFTER_SIGN_OUT"
      );
      let isSuccessful = false;

      if (signInRedirectUrl) {
        appContext?.setIsLoadingAuthAction((prevState) => {
          return { ...prevState, loggingIn: true };
        });

        if (router.asPath.includes("access_token")) {
          isSuccessful = true;
        }
        try {
          await router.push(slashBeforeUrlAdder(signInRedirectUrl));
        } catch {
          await router.push("/");
        } finally {
          localStorage.removeItem("REDIRECT_AFTER_LOGIN");
          appContext?.setIsLoadingAuthAction((prevState) => {
            return { ...prevState, loggingIn: false };
          });
        }

        if (isSuccessful) {
          appContext?.setShowToastMessage(true);
          appContext?.setToastMessage("You have successfully signed in.");
          setTimeout(() => {
            appContext?.setShowToastMessage(false);
          }, 3000);
        }
      }

      if (signOutRedirectUrl) {
        appContext?.setIsLoadingAuthAction((prevState) => {
          return { ...prevState, loggingOut: true };
        });
        try {
          await router.push(slashBeforeUrlAdder(signOutRedirectUrl));
        } catch {
          await router.push("/");
        } finally {
          localStorage.removeItem("REDIRECT_AFTER_SIGN_OUT");
          appContext?.setIsLoadingAuthAction((prevState) => {
            return { ...prevState, loggingOut: false };
          });
        }
      }
    };
    signInOutRedirectFunction().catch((error) => {
      console.error("Auth redirect error: ", error);
    });
  }, []);

  return <div></div>;
};

export default RedirectingPage;

export const getStaticProps: GetStaticProps = async () => {
  const { mediaAssets } = await baseApiCalls();
  return {
    props: {
      pageType: PAGE_TYPES.RedirectingPage,
      mediaAssets,
    },
  };
};
