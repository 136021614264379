import { FiltersContext } from "providers/FiltersProvider";
import { useCallback, useContext } from 'react'
import useAppContext from '@hooks/useAppContext'


export default function useFindYourTripClickCallback() {
  const filtersContext = useContext(FiltersContext);
  const appContext = useAppContext();

  const onFindYourTripClick = useCallback(() => {
    filtersContext?.removeExperience();
    filtersContext?.setGlobalSearch((prevState) => {
      return {
        ...prevState,
        value: true,
      };
    });
    filtersContext?.openFilterModal();
    appContext?.setShowHeaderMenuMobile(false);
  }, []);

  return onFindYourTripClick;
}