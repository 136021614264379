import { useEffect, useState } from "react";
import { useDebouncedCallback } from "./debounceHook";

interface IScreenType {
  type: string;
  variant: string;
  size: { height: number; width: number };
  isScreenReady: boolean; // New property to track readiness of screen size
}

const WAIT_TIME = 200;

const useScreenType = (): IScreenType => {
  const [screenType, setScreenType] = useState<IScreenType>({
    type: "mobile",
    variant: "",
    size: { height: 0, width: 0 },
    isScreenReady: false, // Initially set to false
  });

  const handleWindowResize = (): void => {
    let newScreenType = {
      type: "mobile",
      variant: "",
      size: { height: window.innerHeight, width: window.innerWidth },
      isScreenReady: true, // Set to true as we now have a determined screen size
    };

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      newScreenType.type = "mobile";
      newScreenType.variant = "real";
    } else if (window.innerWidth < 576) {
      newScreenType.type = "mobile";
    } else if (window.innerWidth < 768) {
      newScreenType.type = "tablet";
    } else if (window.innerWidth < 992) {
      newScreenType.type = "desktop";
      newScreenType.variant = "small";
    } else if (window.innerWidth < 1440) {
      newScreenType.type = "desktop";
      newScreenType.variant = "medium";
    } else {
      newScreenType.type = "desktop";
      newScreenType.variant = "large";
    }

    setScreenType(newScreenType);
  };

  useEffect(() => {
    handleWindowResize(); // This will set isScreenReady to true initially
  }, []);

  const debouncedHandleResize = useDebouncedCallback(
    handleWindowResize,
    WAIT_TIME,
    {
      maxWait: WAIT_TIME,
    }
  );

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);

  return screenType;
};

export default useScreenType;