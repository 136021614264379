import dateRangePickerConfigNamu from "@components/utils/dateRangePickerConfigNamu";
import { Context, useContext } from "react";
import { IUseCalendarReturn } from "@hooks/useCalendar";
import { FiltersContext, IFiltersContext } from "providers/FiltersProvider";
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import "react-datepicker/dist/react-datepicker.css";


interface IHorizontalCalendar
  extends Pick<
    IUseCalendarReturn,
    | "monthsShown"
    | "setStartDate"
    | "startDateState"
    | "endDateState"
    | "setEndDate"
  > {
  isMobilePopUp?: boolean;
  observe?: string;
}

const HorizontalCalendar = ({
                              monthsShown,
                              startDateState,
                              setStartDate,
                              endDateState,
                              setEndDate,
                            }: IHorizontalCalendar): JSX.Element => {
  const { changeCalendarSelection } = useContext<IFiltersContext>(
    FiltersContext as Context<IFiltersContext>
  );

  const onDatesUpdate = (dates: [Date | null, Date | null]): void => {
    const [startDate, endDate] = dates.map(dt => dt ? dayjs(dt) : null);

    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      changeCalendarSelection(true);
    }
  };

  return (
    <DatePicker
      {...dateRangePickerConfigNamu}
      monthsShown={monthsShown}
      startDate={startDateState?.toDate()}
      endDate={endDateState?.toDate()}
      onChange={onDatesUpdate}
    />
  );
};

export default HorizontalCalendar;
