
const TwitterIcon = (): JSX.Element => {
  return (
    <div>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9442 4.86768C17.9569 5.04533 17.9569 5.22303 17.9569 5.40069C17.9569 10.8195 13.8326 17.0632 6.29444 17.0632C3.97209 17.0632 1.81473 16.3905 0 15.2231C0.329962 15.2611 0.64719 15.2738 0.989848 15.2738C2.90607 15.2738 4.67006 14.6266 6.0787 13.5226C4.27666 13.4845 2.7665 12.3043 2.24618 10.6799C2.50001 10.7179 2.7538 10.7433 3.02032 10.7433C3.38833 10.7433 3.75638 10.6925 4.099 10.6038C2.22083 10.223 0.812152 8.57328 0.812152 6.58089V6.53014C1.35782 6.83472 1.99239 7.02507 2.66493 7.05042C1.56087 6.31436 0.837542 5.05803 0.837542 3.6367C0.837542 2.87529 1.04055 2.17731 1.3959 1.56817C3.41369 4.05549 6.4467 5.67983 9.8477 5.85752C9.78426 5.55295 9.74617 5.23573 9.74617 4.91846C9.74617 2.65954 11.5736 0.819458 13.8452 0.819458C15.0254 0.819458 16.0914 1.31438 16.8401 2.11388C17.7665 1.93622 18.6548 1.59356 19.4416 1.12403C19.137 2.07583 18.4898 2.87533 17.6396 3.38291C18.4645 3.29412 19.264 3.06564 20 2.74841C19.4417 3.56056 18.7437 4.28389 17.9442 4.86768Z"
          fill="#10247E"
        />
      </svg>
    </div>
  );
};

export default TwitterIcon;
