export const TAG_TYPES = {
  TAG: "Tag",
  ACTIVITY: "Activity",
  ACTIVITY_TYPE: "Activity Type",
  LOCATION: "Location",
  LOCATION_TYPE: "Location Type",
  PROPERTY_TYPE: "Property Type",
} as const;

export const RENTAL_OPTIONS = {
  PEOPLE: "people",
  BEDROOM: "bedroom",
  BATHROOM: "bathroom",
} as const;

export const COUNT_ACTIONS = {
  INCREMENT: "increment",
  DECREMENT: "decrement",
} as const;

export const FILTER_OPTION_TYPE = {
  LOCATIONS: "locations",
  LOCATION_TYPES: "locationTypes",
  ACTIVITIES: "activites",
  ACTIVITY_TYPES: "activityTypes",
  EXPERIENCES: "experiences",
} as const;

export const PATTERNS = {
  EMAIL_VALIDATION_PATTERN:
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
} as const;

export const BUDGETS = {
  MIN: 100,
  MAX: 1000,
  INCREMENT: 50,
} as const;

export const TRAVELERS = {
  ROOMS: { DEFAULT: 1, MAX: 9 },
} as const;

export const STATIC_LANDING_PAGES_URLS = {
  LOCATIONS: "/places-to-visit",
  REVIEWS: "/reviews-testimonials",
  EXPERTS: "/our-travel-agency/planners",
  PACKAGES: "/packages",
  ACCOMMODATIONS: "/stay",
  BEST: "/best",
  ACTIVITIES: "/things-to-do",
  ABOUT: "/our-travel-agency",
} as const;

export const FILTER = {
  STEP: {
    ONE: "stepOne",
    TWO: "stepTwo",
    THREE: "stepThree",
    FOUR: "stepFour",
    FIVE: "stepFive",
  },
  EXPERIENCE: {
    filterType: "Experiences_Filter",
    strapiName: "tag",
    name: "experiences",
  },
  CALENDAR: {
    name: "calendar",
  },
  LOCATION_TYPE: {
    filterType: "Locations_Filter",
    strapiName: "location_type",
    name: "locationTypes",
  },
  ACTIVITY_TYPE: {
    filterType: "Activity_Type_Filter",
    strapiName: "activity_type",
    name: "activityTypes",
  },
  ACCOMMODATIONS: {
    filterType: "Accommodation_Filter",
    strapiName: "property_type",
    name: "accommodations",
    propertyCategory: "Property_Category",
    hotelTitle: "Hotel",
    rentalTitle: "Rental",
    allHotelsSelectedParam: "HotelAll",
    allRentalsSelectedParam: "RentalAll",
  },
} as const;

export const LOCAL_STORAGE = {
  userSearchFilters: "USER_SEARCH_FILTERS",
  userSearchQueryParams: "USER_SEARCH_QUERY_PARAMS",
} as const;

export const FILTER_BY = {
  ALL: "all",
  TAG: "tag",
  LOCATION: "location",
  REGION: "region",
} as const;

export const RAQ_FORM_TYPES = {
  FORM_A: "Form A",
  FORM_B: "Form B",
} as const;

type RaqFormTypesKeys = keyof typeof RAQ_FORM_TYPES;
export type RaqFormTypes = (typeof RAQ_FORM_TYPES)[RaqFormTypesKeys];

export const RESERVED_URLS = {
  FIND_TRIP: "/find-trip",
} as const;

export const RAQ_PAGE_TYPE = {
  PACKAGE: "package",
  ACTIVITY: "activity",
  ACCOMMODATION: "accommodation",
  TRAVEL_CONSULTANT: "travel_consultant",
  PAGE: "page",
  ARTICLE: "article",
  LANDING_TAG: "landing_tag",
  LANDING_LOCATION: "landing_location",
  LANDING_REGION: "landing_region",
  LANDING_ALL: "landing_all",
} as const;

export const IMAGE_QUALITY = {
  DESKTOP: 95,
  MOBILE: 75,
} as const;

type RaqPageTypeKeys = keyof typeof RAQ_PAGE_TYPE;
export type RaqPageType = (typeof RAQ_PAGE_TYPE)[RaqPageTypeKeys];

export const LANDING_FILTER_TYPES = {
  TAG: "tag",
  LOCATION: "location",
  REGION: "region",
  ALL: "all",
} as const;

type LandingFilterTypeKeys = keyof typeof LANDING_FILTER_TYPES;
export type LandingFilterType =
  (typeof LANDING_FILTER_TYPES)[LandingFilterTypeKeys];

export const JSON_SCHEMAS = {
  BREADCRUMB: "Breadcrumb",
  LOCAL_BUSINESS: "LocalBusiness",
  FAQ: "FAQ",
  ATTRACTION: "ATTRACTION",
  HOTEL: "HOTEL",
  TRIP: "TRIP",
  PERSON: "PERSON",
} as const;

type JsonLdSchemaKeys = keyof typeof JSON_SCHEMAS;
export type JsonLdSchemas = (typeof JSON_SCHEMAS)[JsonLdSchemaKeys];

export const CONTACT_FORM_INITIAL_VALUE = {
  fullName: "",
  email: "",
  phoneNumber: "",
  description: "",
};
