import getConfig from 'next/config'
import { useRouter } from 'next/router'
import type { IUSPComponentResponse } from '../types/componentResponses'
import Icon from './icon'

interface IUniqueSellingPoints {
  usp: IUSPComponentResponse[]
}

function UniqueSellingPoints({ usp }: IUniqueSellingPoints): JSX.Element {
  const { publicRuntimeConfig } = getConfig()
  const router = useRouter()

  return (
    <div
      id='usp-container'
      className={`relative h-[500px] z-[11] py-[0.5rem] desktop:mt-[4.5rem] desktop:h-[180px] bg-[#010202]`}
    >
      <div className='flex flex-col items-center justify-center h-full desktopSmall:flex-row desktopSmall:max-w-[90rem] desktopSmall:mx-auto'>
        {usp.map((element) => {
          let url = ''

          const handleClick = (url: string) => {
            if (url) return router.push(url)
          }

          if (element.USP_Text_Secondary_Url)
            url = `${publicRuntimeConfig.WEB_URL}${element.USP_Text_Secondary_Url}`

          return (
            <div
              onClick={() => handleClick(url)}
              className={`flex flex-col items-center w-full h-[160px] py-[1rem] border-darkgray border-b last:border-b-0 hover:usp-item-hover hover:cursor-pointer px-[20px] desktop:px-[70px] desktop:border-b-0 desktop:border-r last:border-r-0`}
              key={element.id}
            >
              {element.USP_Icon_Primary && (
                <Icon name={element.USP_Icon_Primary} width={44} height={44} />
              )}
              <span
                className={`my-2 text-p2 text-center font-normal text-white underline ${!element.USP_Icon_Primary ? 'desktop:mt-[52px]' : ''}`}
              >
                {element.USP_Text_Primary}
              </span>
              <p className='text-p text-center font-normal text-blue-20'>
                {element.USP_Text_Secondary}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default UniqueSellingPoints
