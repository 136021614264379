import { Dispatch, SetStateAction, useEffect } from "react";
import { Dayjs } from 'dayjs'

// Dates includes start date on index 1 and end date on index 2
const useFormatDate = (
  setter: Dispatch<SetStateAction<string | null>>,
  dates: Array<Dayjs | null>,
  numOfNights: number | null
): void => {
  useEffect(() => {
    if (dates[0] && dates[1]) {
      /**
       * https://mentormate.atlassian.net/browse/NAM-431
       * https://mentormate.atlassian.net/browse/NAM-70?focusedCommentId=167049
       * https://mentormate.atlassian.net/browse/NAM-70?focusedCommentId=166353
       */
      const sDate = dates[0].format("MMM DD");
      const eDate = dates[1].format("MMM DD");

      const eDateDay = dates[1].format("DD");

      const sYear = dates[0].format("YYYY");
      const year = dates[1].format("YYYY");
      const nYear = sYear !== year ? sYear : false;

      const sameMonth = dates[0].format("MMM") === dates[1].format("MMM");
      let textField =
        sDate + (nYear ? ", " + nYear : "") + " - " + eDate + ", " + year;

      if (sameMonth) {
        textField = sDate + " - " + eDateDay + ", " + year;
      }
      setter(`${textField} - ${numOfNights} nights`);
    }
  }, [dates[0], dates[1], numOfNights]);
};

export default useFormatDate;

export const generateNumberOfNightsText = (
  numberOfNights: number | null | undefined,
  prefix?: string
): string => {
  return numberOfNights
    ? `${prefix ?? ""}${numberOfNights}\xa0Night${
      numberOfNights > 1 ? "s" : ""
    }`
    : "";
};
