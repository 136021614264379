import Link from 'next/link'

const FindTripButton = ({
                          children,
                          className,
                        }: {
  children: string | string[] | JSX.Element | JSX.Element[] | null;
  className?: string;
}): JSX.Element => {

  return (
    <div className={className}>
      <Link href="/packages">
        {children}
      </Link>
    </div>
  );
};

export default FindTripButton;
