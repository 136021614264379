import BackDrop from '@components/common/BackDrop'
import ExitButton from '@components/icons/ExitButton'
import Button from '@components/common/Button'

const CONTENT = {
  CLEAR_ALL: {
    TITLE: "Clear All Filters",
    TEXT: "Are you sure you want to clear all filters?",
  },
};

interface IConfirmationModal {
  cancelHandler: () => void;
  confirmHandler: () => void;
}

const ConfirmationModal = ({
                             cancelHandler,
                             confirmHandler,
                           }: IConfirmationModal): JSX.Element => {
  return (
    <div className="fixed top-1/2 left-1/2 z-[100] h-screen w-screen -translate-x-1/2 -translate-y-1/2 transform">
      <BackDrop isDark={true} closeModalHandler={cancelHandler}/>
      <div
        className={`fixed top-1/2 left-1/2 z-[101] flex h-[210px] w-[350px] -translate-x-1/2 -translate-y-1/2 transform flex-col justify-between rounded-3xl bg-white p-4`}
      >
        <div className="relative mx-[14px] my-[5px] flex h-[44px] shrink-0 flex-row items-center overflow-hidden">
          {cancelHandler && (
            <div className="ml-auto mr-0 h-fit w-fit ">
              <ExitButton onClick={cancelHandler}/>
            </div>
          )}
          <div className="absolute z-[-1] w-full items-center">
            <div className="mx-auto flex w-fit flex-row items-center gap-2">
              <h5 className="text-p font-medium desktop:text-h5">
                {CONTENT.CLEAR_ALL.TITLE}
              </h5>
            </div>
          </div>
        </div>
        <div
          className={`mx-auto flex w-fit flex-col items-center border-b-[1px] border-blue-10 pb-5`}
        >
          <p className="mx-auto text-p14">{CONTENT.CLEAR_ALL.TEXT}</p>
        </div>
        <div className="bottom-0 flex flex-row">
          <Button variant="no-outline" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmHandler}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
