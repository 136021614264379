import PhoneIcon from "@components/icons/PhoneIcon";
import Link from "next/link";

interface IFooterRibbonCTA {
  text?: string;
  phoneAction?: string;
  phoneNumber?: string;
}

const FooterRibbonCTA = ({
  text,
  phoneAction,
  phoneNumber,
}: IFooterRibbonCTA): JSX.Element => {
  return (
    <div className="flex min-h-[7.5rem] w-full items-center bg-blue-10 desktop:min-h-[5.375rem]">
      <div className="mx-auto w-fit p-5 text-center align-middle text-h6 font-medium leading-[2rem] desktop:px-0">
        {text}
        {phoneAction && phoneNumber && (
          <Link
            className="whitespace-nowrap"
            href={phoneAction}
            prefetch={false}
            title={`Call ${phoneNumber}`}
          >
            <div className="mx-2 -mb-0.5 inline-block h-[1.125rem] w-[1.125rem]">
              <PhoneIcon color="red" width={18} height={18} />
            </div>
            <span className="whitespace-nowrap leading-6 text-orange">
              {phoneNumber}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default FooterRibbonCTA;
