
const HotelIcon = (): JSX.Element => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.69043 20.5132V9.91113H10.4367"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4365 21.2106V0.789062L24.3428 4.91941V21.2106"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.54932 21.2109L26.4514 21.2109"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3301 20.5133V17.0801H19.4488V21.2104"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5394 12.6211H6.58691"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5394 15.1074H6.58691"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5287 9.99707H13.5762"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5287 12.4834H13.5762"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7823 9.99707H17.5176"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7823 12.4834H17.5176"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HotelIcon;
