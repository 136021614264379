import FindTripButton from "@components/common/FindTripButton";
import { RESERVED_URLS } from "@components/utils/constants";
import { slashBeforeUrlAdder } from "@components/utils/urlHelpers";
import { IMenuItemResponse } from "@customTypes/commonResponses";
import Link from "next/link";

interface IHeaderMenuColumn {
  item: IMenuItemResponse;
}

const HeaderMenuColumn = ({ item }: IHeaderMenuColumn): JSX.Element => {
  const hasChildren = item.children ? item.children.length > 0 : false;

  return (
    <div className="my-6 desktop:my-0">
      {hasChildren ? (
        <>
          <div className="-mb-3 text-p13 font-medium text-gray desktop:mb-0 desktop:px-3">
            {item.title}
          </div>
          {item?.children?.map((child) => {
            return child.url !== RESERVED_URLS.FIND_TRIP ? (
              <Link
                href={slashBeforeUrlAdder(child.url)}
                prefetch={false} key={child.id}>
                <div className="my-6 font-medium text-black desktop:my-0 desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10">
                  {child.title}
                </div>
              </Link>
            ) : (
              <FindTripButton className="my-6 cursor-pointer font-medium text-black desktop:my-0 desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10">
                {child.title}
              </FindTripButton>
            );
          })}
        </>
      ) : item.url !== RESERVED_URLS.FIND_TRIP ? (
        <Link
          href={slashBeforeUrlAdder(item.url)}
          prefetch={false}>
          <div className="font-medium text-black desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10">
            {item.title}
          </div>
        </Link>
      ) : (
        <FindTripButton className="cursor-pointer font-medium text-black desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10">
          {item.title}
        </FindTripButton>
      )}
    </div>
  );
};

export default HeaderMenuColumn;
