
const RentalIcon = (): JSX.Element => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1981 9.24129L11.5004 0.819336L0.802734 9.24129"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.47717 7.8764V22.1814H19.5237V7.53516"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.19336 21.8537V12.8789H14.0474V21.8537"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.802734 22.1816H22.1981"
        stroke="#112173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RentalIcon;
