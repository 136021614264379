import GradientCover from "@components/common/GradientCover";
import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import { onEnterDown } from "@components/utils/accessibilityHelpers";
import { IQueryItemActivityType, IQueryItemExperience, IQueryItemLocationType, } from "@customTypes/filtersProvider";
import useAppContext from "@hooks/useAppContext";
import CheckMark from '@components/icons/CheckMark'

type IItemType =
  | IQueryItemExperience
  | IQueryItemLocationType
  | IQueryItemActivityType;

interface IFilterModalCard {
  item: IItemType;
  onClickHandler: (item: IItemType) => void;
}

const FilterModalCard = ({
                           item,
                           onClickHandler,
                         }: IFilterModalCard): JSX.Element => {
  const { isDesktop } = useAppContext();
  const image = item.Media?.url;

  return (
    <div
      className={`relative h-[176px] w-full cursor-pointer desktop:h-[220px]`}
      onClick={() => onClickHandler(item)}
      onKeyDown={(e) => onEnterDown(e, () => onClickHandler(item))}
      tabIndex={0}
    >
      <div
        className={`relative rounded-xl ${
          item.isChecked ? "ring-[2px] ring-orange" : ""
        }  h-[176px] w-full overflow-hidden desktop:h-[220px]`}
      >
        <GradientCover rounded={true} opacity={80}/>
        <ImageCDN
          className="rounded-xl"
          src={ImageDefault(image)}
          alt="w"
          layout="fill"
          objectFit="cover"
          width={340}
          height={220}
        />
        <p className="absolute bottom-1 z-[2] flex w-full justify-center px-[10px] pb-[10px] text-center text-p14 font-[400] leading-[22px] text-white">
          {item.title}
        </p>
        {item.isChecked && (
          <div
            className={`absolute top-2 left-2 flex h-5 w-5 items-center rounded-md bg-orange ring-[2px] ring-white`}
          >
            <CheckMark color="white"/>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterModalCard;
