interface IPhoneIcon {
  color?: 'white' | 'red';
  width?: number | string;
  height?: number | string;
}

export default function PhoneIcon({ color = 'red', width = '100%', height = '100%' }: IPhoneIcon): JSX.Element {
  return (
    <img src={`/images/icons/phone-icon-${color}.png`} alt="phone-icon" style={{ width, height }}/>
  );
}
