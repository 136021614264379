import { IFooterResponse } from "@customTypes/commonResponses";
import { IFooterTabComponentResponse } from "@customTypes/componentResponses";
import useAppContext from "@hooks/useAppContext";
import { useMemo } from "react";
import FooterSection, { IFooterSection } from "./FooterSection";
import FooterTripadvisorLogo from '@components/footer/FooterTipadvisorLogo'

const FooterLayout = ({
                        footerSections,
                        footer,
                      }: {
  footerSections: Array<
    | {
    section: IFooterTabComponentResponse;
    links: IFooterTabComponentResponse[];
  }
    | undefined
  >;
  footer: IFooterResponse;
}): JSX.Element => {
  const { isMobile } = useAppContext();

  const footerLayout = useMemo(() => {
    if (!footerSections.length)
      // Empty footer
      return null;
    if (footerSections.length === 1) {
      // One tab footer
      const section = footerSections[0];
      return (
        <div className="mx-auto flex flex-col gap-10 px-5 desktop:w-fit desktop:flex-row desktop:gap-20 desktop:px-0">
          <div className="order-2 flex-1 self-center desktop:order-1">
            <FooterSection key={section?.section.id} section={section}/>
          </div>
          {footer?.TripAdvisor_Award && (
            <div className="order-1 mx-auto flex items-center justify-center desktop:order-2 desktop:mb-[2rem]">
              <FooterTripadvisorLogo
                tripadvisorLogo={footer?.TripAdvisor_Award}
              />
            </div>
          )}
        </div>
      );
    } else {
      // Rest of tab cases
      let sectionA: IFooterSection | undefined,
        sectionB: IFooterSection | undefined,
        sectionC: IFooterSection | undefined,
        sectionD: IFooterSection | undefined;

      if (footerSections.length === 2) {
        if (isMobile) {
          sectionC = footerSections[0];
          sectionD = footerSections[1];
        } else {
          sectionB = footerSections[0];
          sectionC = footerSections[1];
        }
      } else {
        sectionA = footerSections[0];
        sectionB = footerSections[1];
        sectionC = footerSections[2];
        sectionD = footerSections?.[3];
      }

      const footerTab = (section?: IFooterSection): JSX.Element => {
        return section ? (
          <div className={"flex-1"}>
            <FooterSection section={section}/>
          </div>
        ) : (
          <div className="flex-1"/>
        );
      };

      return (
        <div className="flex w-full flex-col gap-10 px-5 desktop:flex-row desktop:gap-20 desktop:px-0">
          <div className="flex flex-1 flex-row gap-5">
            {footerTab(sectionA)}
            {footerTab(sectionB)}
          </div>
          {footer?.TripAdvisor_Award && (
            <div className="mx-auto mb-[2rem] flex items-center justify-center">
              <FooterTripadvisorLogo
                tripadvisorLogo={footer?.TripAdvisor_Award}
              />
            </div>
          )}
          <div
            className={`flex flex-1 flex-row gap-5 ${
              isMobile && footerSections.length === 3 ? "self-center" : ""
            }`}
          >
            {footerTab(sectionC)}
            {(!isMobile || footerSections.length !== 3) && footerTab(sectionD)}
          </div>
        </div>
      );
    }
  }, [footerSections, footer?.TripAdvisor_Award, isMobile]);

  return (
    <div className="flex flex-col desktop:m-auto desktop:flex-row-reverse desktop:justify-between desktop:pt-[52px]">
      <div className="flex w-full flex-col desktop:flex-row">
        {footerLayout}
      </div>
    </div>
  );
};

export default FooterLayout;
