import ModalHeader from "@components/common/ModalHeader";
import MediaAssets from "@components/utils/MediaAssets";
import useAppContext from "@hooks/useAppContext";
import useFocus from "@hooks/useFocus";
import useModalScrollFreeze from "@hooks/useModalScrollFreeze";
import useScreenType from "@hooks/useScreenType";
import { FiltersContext } from "providers/FiltersProvider";
import React, { useContext } from "react";
import Image from 'next/image'

interface IProps {
  title: string;
  subtitle?: string;
  maxDesktopWidth?: number;
  hasLogo?: boolean;
  onBackHandler?: () => void;
  onCloseHandler?: () => void;
  children?: JSX.Element | JSX.Element[];
  zIndex?: number;
  isFilterModal?: boolean;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}

const ModalContainer = ({
                          title,
                          subtitle,
                          maxDesktopWidth,
                          hasLogo,
                          onBackHandler,
                          onCloseHandler,
                          children,
                          zIndex,
                          isFilterModal,
                          scrollContainerRef,
                        }: IProps): JSX.Element => {
  const appContext = useAppContext();
  const filtersContext = useContext(FiltersContext);
  const screenType = useScreenType();

  let maxModalHeight = "";
  if (typeof window !== "undefined") {
    window.innerHeight < 1000
      ? (maxModalHeight = "desktop:max-h-[95%]")
      : (maxModalHeight = "desktop:max-h-[880px]");
  }

  useModalScrollFreeze([appContext.isMobile]);
  const focusRef = useFocus();
  return (
    <div
      tabIndex={0}
      ref={focusRef}
      className={`fixed top-0 flex h-screen w-full flex-col ${isFilterModal ? 'bg-white' : 'bg-[#F3F4F8]'} focus:outline-none desktop:top-1/2 desktop:left-1/2 desktop:z-[1000] desktop:m-0 desktop:h-fit desktop:-translate-x-1/2 desktop:-translate-y-1/2 desktop:transform desktop:p-0 ${maxModalHeight} desktop:rounded-3xl`}
      style={
        appContext?.isMobile || appContext?.isTablet
          ? { zIndex: zIndex ?? 1000 }
          : {
            width: maxDesktopWidth ?? 800,
          }
      }
      aria-label={`${title ?? ""} ${subtitle ?? ""}`}
    >
      <ModalHeader
        title={title}
        subtitle={subtitle}
        hasLogo={hasLogo}
        onBackHandler={onBackHandler}
        onCloseHandler={onCloseHandler}
        isFilterModal={isFilterModal}
      />
      <div
        className={`flex flex-col overflow-y-auto overflow-x-hidden bg-[#F3F4F8] desktop:bg-white desktop:mx-8 desktop:mb-5 desktop:pt-0`}
        ref={scrollContainerRef}
        style={{
          height: appContext?.isMobile
            ? screenType.size.height - (isFilterModal ? 0 : 80)
            : undefined,
        }}
      >
        {children}
      </div>
      {appContext.isMobile &&
        filtersContext?.globalSearch.value &&
        !filtersContext?.globalSearch.hasSelected && (
          <div
            className="absolute left-[-98%] top-[-68%] z-[-1] h-[300%] w-[300%] opacity-[60%]"
          >
            <Image
              src={MediaAssets.getAsset("topo_background")}
              fill
              alt="topgraphical background"
              style={{
                objectFit: 'cover',
                transform: "rotate(69.22deg)",
              }}
            />
          </div>
        )}
    </div>
  );
};

export default ModalContainer;
