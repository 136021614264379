import getConfig from "next/config";
import Script from "next/script";

// Noscript part of GTM can be found in _document.tsx
const GoogleTagManager = (): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  return (
    <Script
      strategy="lazyOnload"
      defer={true}
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_ID}');`,
      }}
    />
  );
};

export default GoogleTagManager;
