import { IUseCalendarReturn } from "@hooks/useCalendar";
import { FiltersContext, IFiltersContext } from "providers/FiltersProvider";
import { Context, useCallback, useContext, useEffect, useRef, useState, } from "react";
import dateRangePickerConfigNamu from "../utils/dateRangePickerConfigNamu";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs'


interface ISearchCalendar
  extends Pick<
    IUseCalendarReturn,
    | "setStartDate"
    | "setCalendarStatus"
    | "monthsShown"
    | "setMonthsShown"
    | "startDateState"
    | "endDateState"
    | "setEndDate"
  > {
  isMobilePopUp?: boolean;
  observe?: string;
}

const CALENDAR_BLOCK_HEIGHT = 240; // The height of one block of the calendar (including days and month name)

const SearchCalendar = ({
                          setCalendarStatus,
                          monthsShown,
                          setMonthsShown,
                          startDateState,
                          setStartDate,
                          endDateState,
                          setEndDate,
                          isMobilePopUp,
                          observe,
                        }: ISearchCalendar): JSX.Element => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const { changeCalendarSelection } = useContext<IFiltersContext>(
    FiltersContext as Context<IFiltersContext>
  );

  const onDatesUpdate = (dates: [Date | null, Date | null]): void => {
    const [startDate, endDate] = dates.map(dt => dt ? dayjs(dt) : null);
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      setCalendarStatus(true);
      changeCalendarSelection(true);
    } else {
      setCalendarStatus(false);
    }
  };

  useEffect(() => {
    if(!setMonthsShown) return
    if (endDateState) {
      const currentDate = dayjs(new Date());
      let monthsCounter = endDateState.diff(currentDate, "months", false) + 2;
      if (endDateState.date() <= currentDate.date()) {
        monthsCounter++;
      }
      setMonthsShown(monthsCounter);
    } else {
      setMonthsShown(4);
    }
  }, [endDateState, setMonthsShown]);

  // Infinitive scroll logic
  const [container, setContainer] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setContainer(document.getElementById(observe || "scroll-container"));
  }, []);

  const handleScroll = useCallback(() => {
    if (
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      container &&
      container?.clientHeight + CALENDAR_BLOCK_HEIGHT + container?.scrollTop >
      container?.scrollHeight
    ) {
      setMonthsShown((prevState) => prevState + 2);
    }
  }, [container]);

  useEffect(() => {
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [container]);
  // End of infinitive scroll logic

  return (
    <div
      className={`mobile-calendar relative bg-blue-5 ${
        isMobilePopUp ? "" : "overflow-hidden"
      }`}
      ref={calendarRef}
    >
      <DatePicker
        {...dateRangePickerConfigNamu}
        monthsShown={monthsShown}
        selectsRange
        startDate={startDateState?.toDate()}
        endDate={endDateState?.toDate()}
        onChange={onDatesUpdate}
      />
    </div>
  );
};

export default SearchCalendar;
