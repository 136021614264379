import { useEffect } from "react";

const useModalScrollFreeze = (deps: any[]): void => {
  useEffect(() => {
    const { scrollY } = window;
    let previousBodyPosition: { top: string } | undefined;
    if (deps.some((dep) => dep === true)) {
      previousBodyPosition = {
        top: document.body.style.top,
      };
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `${-scrollY}px`;
    } else {
      if (previousBodyPosition !== undefined) {
        // Convert the position from "px" to Int
        const y = -parseInt(document.body.style.top, 10);
        // Restore styles
        document.body.style.overflow = "unset";
        document.body.style.position = "unset";
        document.body.style.top = previousBodyPosition.top;
        // Restore scroll
        console.log('useModalScrollFreeze scrollTo')
        window.scrollTo(0, y);
        previousBodyPosition = undefined;
      }
    }

    return () => {
      if (previousBodyPosition !== undefined) {
        // Convert the position from "px" to Int
        const y = -parseInt(document.body.style.top, 10);
        // Restore styles
        document.body.style.overflow = "unset";
        document.body.style.position = "unset";
        document.body.style.top = previousBodyPosition.top;
        // Restore scroll
        console.log('useModalScrollFreeze scrollTo')
        window.scrollTo(0, y);
        previousBodyPosition = undefined;
      }
    };
  }, [deps]);
};

export default useModalScrollFreeze;
