
const PlusButton = ({ onClick }: any): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className="ml-2 flex h-[36px] w-[36px] items-center rounded-xl bg-white p-[0.771rem]"
      aria-label="Plus"
      title="Plus"
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.63279 0.823708L6.58373 11.9209M0.843146 6.34777L11.9403 6.29871"
          stroke="#424242"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export default PlusButton;
