import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import { STATIC_LANDING_PAGES_URLS } from "@components/utils/constants";
import { IImageComponentResponse } from "@customTypes/componentResponses";
import Link from "next/link";

interface IFooterTripadvisorLogoProps {
  tripadvisorLogo: IImageComponentResponse;
}

const FooterTripadvisorLogo = ({
                                 tripadvisorLogo,
                               }: IFooterTripadvisorLogoProps): JSX.Element => {
  return (
    <Link
      href={STATIC_LANDING_PAGES_URLS.REVIEWS}
      prefetch={false}>
      <ImageCDN
        src={ImageDefault(tripadvisorLogo)}
        width={148}
        height={149}
        layout="fixed"
        alt="tripadvisor_award"
      />
    </Link>
  );
};

export default FooterTripadvisorLogo;
