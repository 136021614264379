import React from "react";

interface IMainWrapper {
}

const MainWrapper = ({ children }: React.PropsWithChildren<IMainWrapper>): JSX.Element => {
  // Hardcoded pixels are the height of the navigation bar;
  return (
    <main className="overflow-hidden pt-[4.5rem] min-h-[100vh] desktop:pt-0 desktop:overflow-visible">
      {children}
    </main>
  );
};

export default MainWrapper;
