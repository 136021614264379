import { PAGE_TYPES } from "@customTypes/index";
import baseApiCalls from "@services/baseApiCalls";
import { GetStaticProps } from "next";

const thankYouPaymentPage = (): JSX.Element => {
    return (
        <div/>
    );
};

export default thankYouPaymentPage;

export const getStaticProps: GetStaticProps = async () => {
    const { mediaAssets } = await baseApiCalls();
    return {
        props: {
            pageType: PAGE_TYPES.ThankYouPaymentPage,
            mediaAssets
        }
    };
};