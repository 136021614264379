
interface ICloseButtonProps {
  onClick: () => void;
  serverError?: boolean;
}

const CloseButton = ({
  onClick,
  serverError,
}: ICloseButtonProps): JSX.Element => {
  return (
    <button
      className={`mr-4 h-[2.75rem] w-[2.75rem] rounded-2xl ${
        serverError ? "bg-orange-10" : "bg-blue-10"
      }`}
      onClick={onClick}
      aria-label="Close"
      title="Close"
    >
      <div className="flex justify-center">
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto"
        >
          <path
            d="M1.91357 11.6853L12.2845 1.3144"
            stroke="#10247E"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
          <path
            d="M12.2847 11.6853L1.91377 1.3144"
            stroke="#10247E"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </button>
  );
};

export default CloseButton;
