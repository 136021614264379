
interface Map {
  [key: string]: string;
}

const BUTTON_TEXT: Map = {
  calendar: "+\xa0Add\xa0Dates",
  default: "+\xa0Add",
};

interface IAddButton {
  variant?: string;
}

const addButton = ({ variant }: IAddButton): JSX.Element => {
  return (
    <span className="flex h-[1.75rem] w-fit items-center rounded-full border-[0.125rem] border-dashed border-darkblue px-2 pr-2 text-p13 text-darkblue desktop:pt-1">
      {variant ? BUTTON_TEXT[variant] : BUTTON_TEXT.default}
    </span>
  );
};

export default addButton;
