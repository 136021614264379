import React from "react";

interface Map {
  [key: string]: string | undefined;
}

const COLOR_THEME: Map = {
  default: "bg-darkblue text-white",
  white: "bg-white text-darkgray",
};

interface IChip {
  item: {
    title?: string;
  };
  onClick?: () => void;
  variant?: string;
  cursorPointer?: boolean;
}

const Chip = ({
  item,
  onClick,
  variant,
  cursorPointer,
}: IChip): JSX.Element => {
  const onClickHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    if (onClick) onClick();
  };
  return (
    <div
      className={`z-1 align-center flex ${
        variant ? COLOR_THEME[variant] : COLOR_THEME.default
      } h-[28px] w-fit rounded-full`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={`flex items-center ${
          cursorPointer ? "cursor-pointer" : "cursor-default"
        }`}
      >
        <div
          className={`whitespace-nowrap px-3 pt-[0.125rem] text-p13 font-medium`}
        >
          {item.title}
        </div>

        {onClick && (
          <div
            onClick={onClickHandler}
            className="h-full cursor-pointer rounded-full pr-3 pt-[3px]"
          >
            &#10005;
          </div>
        )}
      </div>
    </div>
  );
};

export default Chip;
