
interface IBackButton {
  onClick: () => void;
  showBackground?: boolean;
}

const BackButton = ({ onClick, showBackground = true }: IBackButton): JSX.Element => {
  return (
    <button
      className={`h-[2.75rem] w-[2.75rem] rounded-2xl ${showBackground ? 'bg-orange-10' : ''}`}
      onClick={onClick}
      aria-label="Back"
      title="Back"
    >
      <div className="flex justify-center">
        <svg
          width="10"
          height="17"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.54492 1.70093L1.87825 8.12969L8.54492 15.0343"
            stroke="#FF7557"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </button>
  );
};

export default BackButton;
